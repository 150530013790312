* {
    box-sizing: border-box;
}
 .Hiring {
     max-width: 600px;
     margin: 0 auto;
}

.buttonHolder{
    text-align: center
}
.Something{
  margin-left: 10px;
  margin-right: 10px;
}

 input[type=text],input[type=email], input[type=tel], textarea {
     width: 100%;
     padding: 12px;
     border: 1px solid #ccc;
     border-radius: 4px;
     box-sizing: border-box;
     margin-top: 6px;
     margin-bottom: 16px;
     resize: vertical;
     color: #444;
}
input[type=text]:focus, input[type=email]:focus, input[type=tel]:focus, textarea:focus{
    color: #444;
}

input[type=textarea] {
     width: 100%;
     padding: 12px;
     border: 1px solid #ccc;
     border-radius: 4px;
     box-sizing: border-box;
     margin-top: 6px;
     margin-bottom: 16px;
     word-break: break-all;
     font-family: Tahoma, sans-serif;
}
 input[type=submit] {
     text-align: center;
     background-color: #4CAF50;
     color: white;
     padding: 12px 20px;
     border: none;
     border-radius: 4px;
     cursor: pointer;
}
 input[type=submit]:hover {
     background-color: #45a049;
}
