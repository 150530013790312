body{
    font-size:16px;
    font-family: Cardo,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight:300;
}

.hor-align {
    text-align: center;
}
.hidden {
    position: absolute;
    top: -1000rem;
    left: -1000rem;
    width: 0;
    height: 0;
}

.fading {
    opacity: 1;
    transition: opacity .3s ease-in-out;
    -moz-transition: opacity .3s ease-in-out;
    -webkit-transition: opacity .3s ease-in-out;
}
.fading:hover {
    opacity: .5;
    transition: opacity .3s ease-in-out;
    -moz-transition: opacity .3s ease-in-out;
    -webkit-transition: opacity .3s ease-in-out;
}

.linking{
    color: #a2a2a2;
    text-decoration: none;
    font-weight: 200;
}
.linking:hover, :focus{
    color: #ccc;
    text-decoration: none;
    font-weight: 500;
}

.a {
    /* The image used */
    background-image: url("./assets/pics/4.jpg");
}

.b {
    /* The image used */
    background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url("./assets/pics/10.JPG");
}

.c {
    /* The image used */
    background: linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url("./assets/pics/7.jpg");
}

.log {
    width:32vw;
    height:28vw;
}

.para {

    /* Set a specific height */
    min-height: 800px;

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.inside {
  color: #fff;
  font-size: 40px;
  line-height: 44px;
  font-weight: 300;
  margin: 0 auto;
  text-align: center;
  width: 90%;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}


.fa-circle {
    display: inline-block;
    border-radius: 50%;
    border: 2px solid;
    width: 70px;
    height: 70px;
    text-align:center;
    float: left!important;
    margin-right: 15px;
}
.top {
    padding-top: 15px;
}

hr {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    height: 2px;
    max-width: 100px;
}
hr.colored {
    border-color: #ef4035;
}

.App-link {
    color: #61dafb;
}

.logStyle {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.medium-btn {
    font-size: 1.5rem;
}

.but-light {
    color: #fff;
    border: 1px solid #fff;
    background: 0 0;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
    text-docoration: none;
}

.but-light:hover {
    background-color: #fff;
    border: 1 solid #444444;
    color: #444444;
}
button:focus {outline:0!important;}
a:active, a:focus {
  outline: 0;
  border: none;
  -moz-outline-style: none;
}

.but-dark {
    color: #444444;
    border: 1px solid #444444;
    background: 0 0;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    transition: all .5s;
    text-docoration: none;
}
.but-dark:hover{
    color:  #E3E7E7;
    border-color: #E3E7E7;
    background-color:#555F61;
}

@media (max-width: 767px){
    .logStyle {
        top:30%;
    }
    .para {
        background-attachment: scroll;
        min-height: 300px;
    }
    .inside {
        font-size: 25px;
        line-height: 28px;
        top: 250px;
        -ms-transform: translateY(-150px);
        -webkit-transform: translateY(-150px);
        transform: translateY(-150px);
    }
}


@media (min-width: 768px){
    hr {
        max-width: 300px;
        margin: 20px auto;
    }

    .para {
        height: 800px;
        background-attachment: fixed;
    }
}
